<template>
  <v-container fluid>
    <v-row class="mb-2 d-flex justify-center">
      <v-col>
        <v-card elevation="2">
          <v-card-title class=""> 검색 결과</v-card-title>
          <v-card-text class="text-left">
            데이터 : {{ totalCount }}건<br />
            사용자 : {{ totalDevice }}명
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2">
          <v-card-title class=""> 기사 뷰</v-card-title>
          <v-card-text class="text-left">
            데이터 : {{ viewCount }}건<br />
            사용자 : {{ viewDevice }}명
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2">
          <v-card-title class=""> 설문 요청</v-card-title>
          <v-card-text class="text-left">
            데이터 : {{ requestCount }}건<br />
            사용자 : {{ requestDevice }}명
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2">
          <v-card-title class=""> 설문 노출</v-card-title>
          <v-card-text style="text-align: left">
            데이터 : {{ impressionCount }}건<br />
            사용자 : {{ impressionDevice }}명
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2">
          <v-card-title> 설문 응답</v-card-title>
          <v-card-text style="text-align: left">
            데이터 : {{ surveyCount }}건<br />
            사용자 : {{ surveyDevice }}명
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mb-5">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" lg="2" sm="12">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              class="justify-md-start"
              transition="scale-transition"
              offset-y
              max-width="100%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_date"
                  v-bind="attrs"
                  v-on="on"
                  label="시작 날짜"
                  class="mr-3"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="start_date"
                @input="menu = false"
                locale="ko-KR"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="2" sm="12">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="100%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="end_date"
                  label="종료 날짜"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-3"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="end_date"
                :min="start_date"
                :max="today"
                @input="menu2 = false"
                locale="ko-KR"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="3" sm="12">
            <v-text-field
              v-model="post_url"
              placeholder="기사 URL"
              label="기사 URL"
              class="mr-3"
              @keyup.enter="searchData()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" sm="12">
            <v-text-field
              v-model="post_search"
              placeholder="기사 내용"
              label="기사 내용"
              @keyup.enter="searchData()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="2" sm="12">
            <v-select
              v-model="type"
              :items="type_items"
              label="종류"
              item-text="name"
              item-value="value"
              class="mr-3"
              @change="searchData()"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="2" sm="12">
            <v-autocomplete
              v-model="media_id"
              :items="media_items"
              label="언론사"
              item-text="name"
              item-value="id"
              no-data-text="존재하지 않는 언론사입니다."
              hide-details
              class="mr-3"
              @change="searchData()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="3" sm="12">
            <v-text-field
              v-model="device_id"
              placeholder="Device ID"
              label="Device ID"
              class="mr-3"
              @keyup.enter="searchData()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" sm="12">
            <v-text-field
              v-model="ip"
              placeholder="IP"
              label="IP"
              class="mr-3"
              @keyup.enter="searchData()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="1"
            sm="12"
            class="mt-2"
            style="white-space: nowrap"
          >
            <v-btn color="primary" @click="searchData()" class="mr-2">
              검색
            </v-btn>
            <v-btn @click="refresh()">초기화</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-simple-table height="100%" class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">종류</th>
                <th class="text-left">언론사</th>
                <th class="text-left">기사 썸네일</th>
                <th class="text-left">기사 제목/주소</th>
                <th class="text-left">설문/응답</th>
                <th class="text-left">Device ID</th>
                <th class="text-left">IP</th>
                <th class="text-left">시간</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td class="text-left">
                  {{
                    item.type === "request"
                      ? "설문 요청"
                      : item.type === "survey"
                      ? "설문 응답"
                      : item.type === "impression"
                      ? "설문 노출"
                      : "기사 뷰"
                  }}
                </td>
                <td class="text-left">{{ item.media }}</td>
                <td class="text-left pa-3">
                  <a :href="item.post_url" target="_blank">
                    <v-img
                      :src="item.post_img_url"
                      :alt="item.post_img_url"
                      width="100px"
                      height="100px"
                    ></v-img>
                  </a>
                </td>
                <td class="text-left">
                  {{ item.post_title }} <br />
                  <a :href="item.post_url" target="_blank">
                    {{ item.post_url }}
                  </a>
                </td>
                <td class="text-left">
                  {{ item.survey }} <br />
                  <span style="text-decoration: underline">{{
                    item.answer
                  }}</span>
                </td>
                <td class="text-left">{{ item.device_id }}</td>
                <td class="text-left">{{ item.ip }}</td>
                <td class="text-left">{{ item.created_at }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          color="primary"
          fab
          dark
          fixed
          bottom
          right
          @click="toTop()"
        >
          <v-icon class="pt-1">mdi-apple-keyboard-control</v-icon>
        </v-btn>
        <v-card v-intersect="infiniteScrolling()"></v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "LogSearch",
  data() {
    return {
      headers: [
        {
          text: "no",
          value: "no",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "종류",
          value: "type",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "언론사",
          value: "media_id",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "기사 썸네일",
          value: "post_img_url",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "기사 제목",
          value: "post_title",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "기사 주소",
          value: "post_url",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "문항",
          value: "survey",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "응답",
          value: "answer",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "Device ID",
          value: "device_id",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "시간",
          value: "created_at",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
      ],
      menu: false,
      menu2: false,
      summary: {},
      items: [],
      media_items: [],
      type_items: [
        { name: "전체", value: "" },
        { name: "기사 뷰", value: "view" },
        { name: "설문 요청", value: "request" },
        { name: "설문 노출", value: "impression" },
        { name: "설문 응답", value: "survey" },
      ],
      media_id: "",
      type: "",
      device_id: "",
      ip: "",
      post_url: "",
      post_search: "",
      today: moment().format("YYYY-MM-DD"),
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      loading: false,
      page: 1,
      pageCount: 1,
      per_page: 20,
      requestCount: null,
      requestDevice: null,
      surveyCount: null,
      surveyDevice: null,
      totalCount: null,
      totalDevice: null,
      impressionCount: null,
      impressionDevice: null,
      viewCount: null,
      viewDevice: null,
      scroll_id: "",
      fab: false,
    };
  },
  created() {
    this.device_id = "";
    this.getData();
  },
  mounted() {
    this.getMedia();
  },
  computed: {
    url() {
      return `api/v1/admin/log?scroll_id=${this.scroll_id}&start_date=${this.start_date}&end_date=${this.end_date}&type=${this.type}&media_id=${this.media_id}&device_id=${this.device_id}&post_url=${this.post_url}&post_search=${this.post_search}&ip=${this.ip}`;
    },
  },
  methods: {
    getData() {
      this.loading = true;
      let items = [];
      this.axios
        .get(this.url, {})
        .then((res) => {
          if (res.data.data.log.length > 0) {
            res.data.data.log.map((item, index) => {
              items.push({
                no: index + 1,
                type: item.type,
                media_id: item.media_id,
                media: item.media,
                post_img_url: item.post_img_url,
                post_title: item.post_title,
                post_url: item.post_url,
                survey: item.survey,
                answer: item.answer,
                device_id: item.device_id,
                ip: item.ip,
                created_at: item.created_at,
              });
            });
          }
          this.items = items;
          this.scroll_id = res.data.data.scrollId;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });

      this.axios
        .get(
          `api/v1/admin/log/summary?start_date=${this.start_date}&end_date=${this.end_date}&type=${this.type}&media_id=${this.media_id}&device_id=${this.device_id}&post_url=${this.post_url}&post_search=${this.post_search}`,
          {}
        )
        .then((res) => {
          this.summary = res.data.data.summary;
          this.viewCount = this.summary.view.count.toLocaleString("ko-KR");
          this.viewDevice = this.summary.view.device.toLocaleString("ko-KR");
          this.requestCount =
            this.summary.request.count.toLocaleString("ko-KR");
          this.requestDevice =
            this.summary.request.device.toLocaleString("ko-KR");
          this.impressionCount =
            this.summary.impression.count.toLocaleString("ko-KR");
          this.impressionDevice =
            this.summary.impression.device.toLocaleString("ko-KR");
          this.surveyCount = this.summary.survey.count.toLocaleString("ko-KR");
          this.surveyDevice =
            this.summary.survey.device.toLocaleString("ko-KR");
          this.totalCount = this.summary.total.count.toLocaleString("ko-KR");
          this.totalDevice = this.summary.total.device.toLocaleString("ko-KR");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    infiniteScrolling() {
      setTimeout(() => {
        this.axios
          .get(this.url)
          .then((res) => {
            if (res.data.data.log.length > 1) {
              res.data.data.log.forEach((item) => this.items.push(item));
            } else {
              return false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500);
    },
    getMedia() {
      this.medias = [];
      this.media_items.push({
        id: "",
        name: "전체",
      });
      this.axios
        .get("api/v1/admin/medias", {})
        .then((res) => {
          if (res.data.data.medias.length > 0) {
            res.data.data.medias.forEach((item, index) => {
              this.media_items.push({
                no: index + 1,
                id: item.id,
                name: item.name,
              });
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    searchData() {
      this.scroll_id = "";
      this.getData();
    },
    refresh() {
      this.scroll_id = "";
      this.start_date = moment().format("YYYY-MM-DD");
      this.end_date = moment().format("YYYY-MM-DD");
      this.media_id = "";
      this.type = "";
      this.device_id = "";
      this.post_url = "";
      this.post_search = "";
      this.getData();
    },
  },
};
</script>
